<template>
  <div id="overlay" v-if="overlay"></div>

  <div>
    <Toast/>

    <div class="card">
      <Steps :model="items" :readonly="true"/>
    </div>

    <Card>
      <template #header>

      </template>
      <template #title>
        Seleccionar Agencia ({{ agencias?formatNumber(agencias.length):'cargando...'}})
      </template>
      <template #content>
        <div class="margin-40">
          <Dropdown v-model="selectedAgencia"
                    :options="agencias"
                    optionLabel="tagencia"
                    placeholder="Punto de venta"
                    class="agencia filterItem"
                    :filter="true"
                    :loading="loadingAgencia"
                    :showClear="true"
                    scrollHeight="600px"/>
        </div>
      </template>
      <template #footer>

        <div class="grid grid-nogutter justify-content-end">
          <Button label="Siguiente"
                  @click="nextPage()"
                  icon="pi pi-angle-right"
                  iconPos="right"
                  :disabled="this.selectedAgencia ? disabled : ''"
          />

        </div>
      </template>
    </Card>

    <router-view v-slot="{Component}" :formData="formObject" @prevPage="prevPage($event)" @nextPage="nextPage($event)"
                 @complete="complete">
      <keep-alive>
        <component :is="Component"/>
      </keep-alive>
    </router-view>


  </div>
</template>

<script>
import MainService from "@/service/backend/MainService";
import generalMixin from "@/mixins/generalMixin";

export default {
  name: "Step1",
  mixins: [generalMixin],
  created() {
    this.mainService = new MainService();
  },
  mounted() {
    this.refreshAgencia();
  },
  data() {
    return {
      items: [
        {
          label: 'Seleccionar Agencia',
          to: '/tickets-anular-1'
        },
        {
          label: 'Seleccionar Ticket',
          to: '/tickets-anular-2'
        }
      ],
      loadingAgencia: false,
      selectedAgencia: null,
      agencias: null,
      pageIndex: 0,
      overlay: false,
    }
  },
  methods: {
    nextPage() {
      this.overlay = true;
      this.$router.push({
        name: 'tickets-anular-2', query: {
          id_agencia: this.selectedAgencia.id_agencia,
          game: this.$router.currentRoute.value.params.game,
        }
      })
    },

    prevPage() {
      this.$router.push('/tickets-anular-1');
    },

    async refreshAgencia() {
      this.overlay = true;
      const today = new Date();
      this.loadingAgencia = true;
      this.selectedAgencia = null;
      this.agencias = await this.mainService.getAgencia({
        fecha: this.formatDate2(today),
        comercializador: null,//this.selectedComercializador,
        banca: null,//this.selectedBanca,
        distribuidor: null,//this.selectedDistribuidor
        game: this.$router.currentRoute.value.params.game,
        identity: this.getIdentity()
      });
      this.loadingAgencia = false;
      this.overlay = false;
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep(b) {
  display: block;
}

::v-deep(.p-card-body) {
  padding: 2rem;
}

.agencia {
  width: 100% !important;
  font-size: large;
}
</style>